.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.heart-container {
  position: relative;
}

.heart {
  position: relative;
  width: 100px;
  height: 90px;
  cursor: pointer;
}

.heart:before,
.heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background-color: red;
  border-radius: 50px 50px 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.message {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: pop 0.5s forwards;
}

@keyframes pop {
  0% {
    top: -50px;
    opacity: 0;
  }

  100% {
    top: -100px;
    opacity: 1;
  }
}
